<template>
    <div>
        <div :class="[withAnimation ? 'content__animation-2 _hidden' : null]"
            v-observe-visibility="$root.visibilityChanged">
            <form action="" ref="form" class="form consult-form__form" @submit.prevent="submit">
                <div class="form__row">
                    <input type="text" class="form__textinput" name="name" placeholder="ФИО"
                        :class="{ 'form__textinput_error': $v.form.name.$error }" v-model="form.name">
                </div>
                <div class="form__row form__row_flex">
                    <input type="text" class="form__textinput" name="phone" v-mask="'+7 ### ### ## ##'"
                        placeholder="Ваш телефон" :class="{ 'form__textinput_error': $v.form.phone.$error }"
                        v-model="form.phone">
                </div>
                <div class="form__row">
                    <div class="form__wrapper" v-mousedown-outside="cityBlur">
                        <input type="text" name="city" autocomplete="suppress-autocomplete" placeholder="Город"
                            class="form__textinput"
                            :class="{ 'form__textinput_error': $v.form.city.$error || isCityError }" v-model="form.city"
                            @input="getCityHandlerEvent($event)" @focus="cityFocused = true"
                            @keydown="citySuggestionKeyDown">
                        <ul v-if="cityFocused && citySuggestion" class="form__suggestion">
                            <li v-for="(item, i) in citySuggestion" :key="i"
                                :class="{ '_active': i === citySuggestionIndex }" @click="setCity(item)">
                                {{ item.label }}</li>
                        </ul>
                    </div>
                </div>
                <div class="form__row form__row_flex">
                    <div class="form__col">
                        <label class="form__checkbox" :class="{ 'form__checkbox_error': $v.form.agree.$error }">
                            <input type="checkbox" v-model="form.agree" @change="$v.form.agree.$touch()">
                            <span class="form__checkbox-box" />
                            <span class="form__checkbox-label">Я согласен с <a href="/upload/processing_conditions.pdf"
                                    target="_blank">условиями обработки данных</a></span>
                        </label>
                    </div>
                    <div class="form__col">
                        <input class="btn btn_pink" type="submit" value="Получить консультацию">
                    </div>
                </div>
            </form>
        </div>
        <div ref="popup" class="popup" v-if="popupVisible">
            <div class="popup__box" v-click-outside="outsideClose">
                <span class="popup__close" @click="close"></span>
                <div class="popup__errors" v-if="$v.$error">
                    <p>Пожалуйста, заполните обязательные поля</p>
                    <p v-if="$v.form.phone.$error">Пожалуйста, укажите корректный телефон</p>
                    <p v-if="$v.form.agree.$error">Необходимо ваше согласие на обработку персональных данных</p>
                    <p v-if="$v.form.city.$error">Неправильно заполнен город</p>
                </div>
                <div class="popup__success" v-else>
                    <p class="popup__title">Спасибо за обращение!</p>
                    <p>Сотрудник Церезит свяжется с Вами в ближайшее время</p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from "axios";
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import throttle from "../utils/throttle";
import { reachGoal } from "../utils/ymStats";
import { tagGA } from "../utils/gaStats";

export default {
    name: 'ConsultForm',
    props: {
        ymId: { type: String },
        gaId: { type: String },
        withAnimation: { type: Boolean, default: false }
    },
    data() {
        return {
            form: {
                name: '',
                phone: '',
                agree: false,
                city: '',
            },
            isCityState: false,
            isCityError: false,
            cityFocused: false,
            citySuggestion: [],
            citySuggestionIndex: -1,
            popupVisible: false,
            dadataApiKey: '239a124478b9dc8c1915d37f140cf9a202080953', // Замените на ваш API-ключ
        };
    },
    validations: {
        form: {
            name: { required, min: minLength(3) },
            phone: { required, min: minLength(16) },
            agree: { sameAs: sameAs(() => true) },
            city: { required },
        },
    },
    methods: {
        async submit() {
            const vm = this;
            this.$v.$touch();
            if (this.$v.$invalid) {
                vm.popupVisible = true;
                setTimeout(function () {
                    vm.popupVisible = false;
                }, 4000);
            } else {
                try {
                    if (!this.isCityState) {
                        this.isCityError = true;
                        this.form.city = "";
                        return;
                    }
                    this.isCityError = false;
                    const response = await axios({
                        url: '/local/ajax/form.php',
                        method: 'POST',
                        data: this.getFormData(),
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    if (response.data.status === 'error') {
                        throw new Error(response.data.errors);
                    }
                    reachGoal(this.ymId);
                    tagGA(this.gaId);

                    if (window.location.pathname.includes('/consultation/')) {
                        !function (e, n) { function o(e, n, o) { n = n || "&", o = o || "="; var d = []; for (var r in e) e.hasOwnProperty(r) && d.push(r + o + encodeURIComponent(e[r])); return d.join(n) } function d(e, n) { var o = e.cookie.match("(^|;) ?" + n + "=([^;]*)(;|$)"); return o ? decodeURIComponent(o[2]) : null } var r, t, i, c, u; r = e, t = n, i = document.domain, c = { tail256: document.referrer || "unknown" }, void 0 !== (u = function (e) { var n = {}; if (e) { var o = e.split("&"); for (var d in o) if (o.hasOwnProperty(d)) { var r = o[d].split("="); void 0 !== r[0] && void 0 !== r[1] && (n[r[0]] = decodeURIComponent(r[1])) } } return n }(window.location.search.substring(1))).adrclid && (r.fsid = u.adrclid), null !== d(document, "adrcid") && (r.cid = d(document, "adrcid")), t && t.id && null !== d(document, t.id) && (r.suid = i + "_" + encodeURIComponent(d(document, t.id))), t && t.gid1 ? r.gid1 = t.gid1 : null !== d(document, "_ga") && (r.gid1 = encodeURIComponent(d(document, "_ga"))), t && t.yid1 ? r.yid1 = t.yid1 : null !== d(document, "_ym_uid") && (r.yid1 = encodeURIComponent(d(document, "_ym_uid"))), r.loc = encodeURIComponent(window.location.href), r.custom && (r.custom = o(r.custom, ";")), function (e, n) { !function (e) { if (e = e.split("![rnd]").join(~~(1e6 * Math.random())), document.createElement && document.body) { var n = document.createElement("img"); n.style.position = "absolute", n.style.display = "none", n.style.width = n.style.height = "0px", n.setAttribute("referrerpolicy", "no-referrer-when-downgrade"), n.src = e, document.body.appendChild(n) } else { var o = new Image; o.setAttribute("referrerpolicy", "no-referrer-when-downgrade"), o.src = e } }("https://ad.adriver.ru/cgi-bin/rle.cgi?" + e + "&rnd=![rnd]" + (n ? "&" + n : "")) }(o(r), o(c)) }
                            ({ sid: 229009, bt: 62, sz: "click_get_consultation_consultation", custom: {} }, { id: "", gid1: "", yid1: "" });
                    } else {
                        !function (e, n) { function o(e, n, o) { n = n || "&", o = o || "="; var d = []; for (var r in e) e.hasOwnProperty(r) && d.push(r + o + encodeURIComponent(e[r])); return d.join(n) } function d(e, n) { var o = e.cookie.match("(^|;) ?" + n + "=([^;]*)(;|$)"); return o ? decodeURIComponent(o[2]) : null } var r, t, i, c, u; r = e, t = n, i = document.domain, c = { tail256: document.referrer || "unknown" }, void 0 !== (u = function (e) { var n = {}; if (e) { var o = e.split("&"); for (var d in o) if (o.hasOwnProperty(d)) { var r = o[d].split("="); void 0 !== r[0] && void 0 !== r[1] && (n[r[0]] = decodeURIComponent(r[1])) } } return n }(window.location.search.substring(1))).adrclid && (r.fsid = u.adrclid), null !== d(document, "adrcid") && (r.cid = d(document, "adrcid")), t && t.id && null !== d(document, t.id) && (r.suid = i + "_" + encodeURIComponent(d(document, t.id))), t && t.gid1 ? r.gid1 = t.gid1 : null !== d(document, "_ga") && (r.gid1 = encodeURIComponent(d(document, "_ga"))), t && t.yid1 ? r.yid1 = t.yid1 : null !== d(document, "_ym_uid") && (r.yid1 = encodeURIComponent(d(document, "_ym_uid"))), r.loc = encodeURIComponent(window.location.href), r.custom && (r.custom = o(r.custom, ";")), function (e, n) { !function (e) { if (e = e.split("![rnd]").join(~~(1e6 * Math.random())), document.createElement && document.body) { var n = document.createElement("img"); n.style.position = "absolute", n.style.display = "none", n.style.width = n.style.height = "0px", n.setAttribute("referrerpolicy", "no-referrer-when-downgrade"), n.src = e, document.body.appendChild(n) } else { var o = new Image; o.setAttribute("referrerpolicy", "no-referrer-when-downgrade"), o.src = e } }("https://ad.adriver.ru/cgi-bin/rle.cgi?" + e + "&rnd=![rnd]" + (n ? "&" + n : "")) }(o(r), o(c)) }
                            ({ sid: 229009, bt: 62, sz: "click_get_consultation_main_page", custom: {} }, { id: "", gid1: "", yid1: "" });
                    }

                    this.resetForm();
                    vm.popupVisible = true;
                    setTimeout(function () {
                        vm.popupVisible = false;
                    }, 4000);
                    this.isCityState = false;
                } catch (e) {
                    console.log('Error!', e);
                    alert('Ошибка!');
                }
            }
        },
        getFormData() {
            const form = new FormData();
            form.append('name', this.form.name);
            form.append('phone', this.form.phone);
            form.append('city', this.form.city);
            return form;
        },
        resetForm() {
            this.$v.$reset();
            this.form.name = "";
            this.form.phone = "";
            this.form.agree = "";
            this.form.city = "";
        },
        getCityHandlerEvent(event) {
            this.form.city = event.target.value.replace(/[^а-яё]/ig, '');
            this.getCity(event.target.value);
        },
        getCity: throttle(function (val) {
            const vm = this;
            if (val.trim().length >= 3) {
                axios.post(
                    'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
                    { 
                      query: val, 
                      count: 10,
                      from_bound: { value: "city" }, // Ограничить поиск городами
                      to_bound: { value: "city" }, // Ограничить поиск поселками
                      restrict_value: true, // Ограничить результаты только указанными типами
                     },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization': `Token ${this.dadataApiKey}`,
                        }
                    }
                ).then(function (response) {
                    const result = response.data.suggestions;
                    vm.citySuggestion = result.map(function (item) {
                        return {
                            label: item.value,
                            response: item.data
                        };
                    });
                }).catch(function (error) {
                    console.error('Ошибка при получении подсказок от DaData:', error);
                });
            } else {
                vm.citySuggestion = [];
            }
        }, 1000),

        setCity(value) {
            this.isCityState = true;
            this.form.city = value.label;
            if (value.response.region) {
                this.form.region = value.response.region;
            }
            this.citySuggestion = [];
            this.cityBlur();
        },

        cityBlur() {
            this.cityFocused = false;
        },

        citySuggestionKeyDown(e) {
            if (!this.cityFocused) {
                return false;
            }

            if (!this.citySuggestion.length) {
                return false;
            }

            if (e.key === 'ArrowUp') {
                e.stopPropagation();
                e.preventDefault();
                if (this.citySuggestionIndex > 0) {
                    this.citySuggestionIndex--;
                } else {
                    this.citySuggestionIndex = this.citySuggestion.length - 1;
                }
            }

            if (e.key === 'ArrowDown') {
                e.stopPropagation();
                e.preventDefault();
                if ((this.citySuggestionIndex + 1) < this.citySuggestion.length) {
                    this.citySuggestionIndex++;
                } else {
                    this.citySuggestionIndex = 0;
                }
            }

            if (e.key === 'Enter') {
                e.stopPropagation();
                e.preventDefault();
                this.setCity(this.citySuggestion[this.citySuggestionIndex]);
                this.citySuggestionIndex = -1;
            }
        },
        outsideClose(e) {
            if (e.target === this.$el) {
                this.close();
            }
        },
        close() {
            this.popupVisible = false;
        },
    },
};
</script>
